import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useMediaQuery, InputAdornment, Stack, Divider, Tooltip, Tabs, Grid2 as Grid, Card, CardContent, Typography, Box, IconButton, Tab, Button, TextField as UncontrolledTextField, Autocomplete} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm, useFieldArray, useFormContext, FormProvider} from 'react-hook-form';
import {doc, getDoc, addDoc, deleteDoc, updateDoc, getDocs, collection, query, where} from 'firebase/firestore';
import {isNil, uniq, get, sortBy, isString} from 'lodash';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import {httpsCallable} from 'firebase/functions';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import {alpha, useTheme} from '@mui/material/styles';
import {orange} from '-/style/themePrimitives';
import {TrainingTypes, TrainingDisciplines, FirefighterRanks} from '@embertracking/common';
import {DataGridPro, GridFooterContainer, gridClasses, useGridApiRef} from '@mui/x-data-grid-pro';
import {v4 as uuid} from 'uuid';

import {db, functions} from '-/firebase';
import {SettingsContext} from '-/contexts/Settings';
import {UserContext} from '-/contexts/User';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import {processRawDoc, processRawDocs, populateUsers, ensureJSDates} from '-/data/utils';
import {Competencies, Standards, getStandardForKey} from '-/data/standards';

import TextField from '-/form/TextField.js';
import DatePickerField from '-/form/DatePickerField.js';
import TimeField from '-/form/TimeField.js';
import SelectField from '-/form/SelectField.js';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import AutocompleteField from '-/form/AutocompleteField';
import CheckboxField from '-/form/CheckboxField';

import SmallChip from '-/components/SmallChip';

import MemberHours from '-/components/members/Hours';

import ImportDialog from '-/pages/responses/ImportDialog';

import {StandardSubsection} from '-/pages/NFPA';

const Skills = () => {
    const {watch, control, getValues, setValue} = useFormContext();
    const membersRaw = watch('members');
    const {users = []} = useContext(UserContext);
    const apiRef = useGridApiRef();
    const [hoveredColumn, setHoveredColumn] = useState(null);

    const {fields, append} = useFieldArray({
        control,
        name: 'memberCompetencies'
    });

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    
    const members = sortBy((membersRaw || []).map(member => {
        if (isString(member)) {
            return users.find(user => user.uid === member);
        }

        return member;
    }).filter(Boolean), ['lastName', 'firstName']);

    useEffect(() => {
        const handleCellMouseOver = params => {
            const {field} = params;

            if (field === 'competency') {
                setHoveredColumn(null);
            } else {
                setHoveredColumn(field);
            }
        };
    
        return apiRef.current.subscribeEvent('cellMouseOver', handleCellMouseOver);
    }, [apiRef]);
      

    const handleAddCompetency = () => {
        append({
            id: uuid(),
            competency: ''
        });
    };

    const handleRemoveCompetency = index => () => {
        const values = getValues('memberCompetencies');
        const newValues = values.filter((value, i) => i !== index);

        setValue('memberCompetencies', newValues, {shouldDirty: true});
    };

    const getIndexByRow = (arr, id) => {
        return arr ? arr.findIndex(r => r.id === id) : -1;
    };

    const handleCellClick = useCallback(params => {
        const {id, field: uid} = params;
        if (uid === 'competency') {
            return;
        }

        const index = getIndexByRow(fields, id);
        if (index === -1) {
            return;
        }

        const value = getValues(`memberCompetencies.${index}.${uid}`);
        setValue(`memberCompetencies.${index}.${uid}`, !value, {shouldDirty: true});
    }, [fields, getValues, setValue]);

    const columns = [
        {
            field: 'competency',
            headerName: '',
            width: isSmall ? 200 : 300,
            hideable: false,
            cellClassName: 'competency',
            renderCell: params => {
                const {id} = params;
                const index = getIndexByRow(fields, id);
                if (index === -1) {
                    return null;
                }

                const options = Object.keys(Competencies).map(competency => {
                    const {label, shortLabel} = Competencies[competency] || {};

                    return {
                        value: competency,
                        label: shortLabel || label
                    };
                }).filter(option => {
                    return !fields.some(field => field.id !== id && field.competency === option.value);
                });

                return (
                    <Stack direction="row" spacing={1} sx={{flex: 1}}>
                        <AutocompleteField
                            label={false}
                            variant="standard"
                            name={`memberCompetencies.${index}.competency`}
                            options={options}
                            filterSelectedOptions={false}
                            objectValue={false}
                            multiple={false}
                            sx={{flex: 1}}
                            onKeyDown={e => e.stopPropagation()}
                        />
                        <IconButton onClick={handleRemoveCompetency(index)}>
                            <ClearIcon />
                        </IconButton>
                    </Stack>
                );
            }
        },
        ...members.map(user => {
            const {uid, fullName} = user;
            return {
                field: uid,
                headerName: fullName,
                sortable: false,
                width: 50,
                headerAlign: 'left',
                headerClassName: 'rotated',
                disableColumnMenu: true,
                cellClassName: 'checkbox',
                renderCell: params => {
                    const {id, field: uid} = params;
                    const index = getIndexByRow(fields, id);
                    if (index === -1) {
                        return null;
                    }
    
                    return (
                        <CheckboxField
                            label={false}
                            name={`memberCompetencies.${index}.${uid}`}
                            size="small"
                        />
                    );
                }
            };
        })
    ];

    // const rows = Object.keys(Competencies).map(competency => {
    //     const {label} = Competencies[competency] || {};

    //     return {
    //         id: competency,
    //         competency: label
    //     };
    // });

    return (
        <DataGridPro
            apiRef={apiRef}
            initialState={{
                pinnedColumns: {
                    left: ['competency']
                }
            }}
            disableRowSelectionOnClick
            disableMultipleRowSelection
            experimentalFeatures={{
                rowPinning: true
            }}
            onCellClick={handleCellClick}
            slotProps={{
                loadingOverlay: {
                    variant: 'linear-progress',
                    noRowsVariant: 'skeleton'
                },
                footer: {
                    children: (
                        <Box sx={{px: 1}}>
                            <Button startIcon={<AddIcon />} onClick={handleAddCompetency}>Add Competency</Button>
                        </Box>
                    )
                }
            }}
            slots={{
                footer: GridFooterContainer
            }}
            sx={{
                [`& .${gridClasses.cell}`]: {
                    py: 1
                },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                    outline: 'none'
                },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                    outline: 'none'
                },

                [`& .MuiDataGrid-cell[data-field="${hoveredColumn}"]`]: {
                    backgroundColor: alpha(orange[100], 0.5)
                },

                '& .MuiDataGrid-cell.checkbox': {
                    padding: 0,
                    justifyContent: 'center'
                },
                '& .MuiDataGrid-cell.competency': {
                    lineHeight: 0
                },

                '.row:has([data-field="bar"]:hover) [data-field="bar"]': {
                    backgroundColor: 'yellow'
                },

                '& .rotated.MuiDataGrid-columnHeader': {
                    padding: '10px'
                },
                '& .rotated .MuiDataGrid-columnHeaderTitleContainer': {
                    flexDirection: 'column-reverse',
                    alignItems: 'center'
                },
                '& .rotated .MuiDataGrid-columnHeaderTitleContainerContent': {
                    writingMode: 'vertical-rl',
                    transform: 'rotate(180deg)',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    overflow: 'visible'
                },
                '& .rotated .MuiDataGrid-iconButtonContainer': {
                    height: 0
                },
                '& .rotated.MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                    height: 'auto'
                },
                '& .MuiDataGrid-treeDataGroupingCellToggle': {
                    mr: isSmall ? 0 : 1,
                    ...(isSmall && {flex: 0})
                },
                '& .MuiDataGrid-cell': isSmall && {
                    pl: 0,
                    pr: 0
                },

                '.grouping-cell-header': {
                    fontWeight: 'bold'
                },

                '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                    outline: 'none'
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none'
                },
                '& .MuiDataGrid-columnSeparator': {
                    display: 'none'
                },
                // '& .MuiDataGrid-columnHeaderTitleContainer': {
                //     justifyContent: 'flex-start !important'
                // },
                '& .MuiDataGrid-columnHeader': {
                    padding: '10px'
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    alignItems: 'flex-end'
                },
                '& .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-columnHeaderTitle': {
                    whiteSpace: 'normal',
                    textAlign: 'center'
                }
            }}
            pageSizeOptions={[]}
            rows={fields}
            columns={columns}
            defaultGroupingExpansionDepth={-1}
            columnHeaderHeight={200}
        />
    );
};

export default function Training() {
    const {id: uid} = useParams();
    const isNew = !uid;
    const [loading, setLoading] = useState(!!uid);
    const [deleting, setDeleting] = useState(false);
    const [guessingJprs, setGuessingJprs] = useState(false);
    const [searchJpr, setSearchJpr] = useState('');
    const [activeTab, setActiveTab] = useState('details');
    const [foundJprs, setFoundJprs] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [otherDocsAtSameTime, setOtherDocsAtSameTime] = useState([]);
    const navigate = useNavigate();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {usesIAR} = useContext(SettingsContext);
    const queryStandards = httpsCallable(functions, 'queryStandards');

    // const openai = new OpenAI({
    //     apiKey: OpenAIKey,
    //     dangerouslyAllowBrowser: true
    // });

    const methods = useForm({
        defaultValues: {
            type: 'PRACTICE'
        },
        mode: 'onChange'
    });
    const {handleSubmit, watch, setValue, formState, reset} = methods;
    const {dirtyFields} = formState;
    const dirtyKeys = Object.keys(dirtyFields);

    const jprs = watch('jprs', []);
    const title = watch('title');
    const hours = watch('hours');
    const date = watch('date');
    const startTime = watch('startTime');
    const endTime = watch('endTime');
    const description = watch('description');
    const members = watch('members', []);
    const absent = watch('absent', []);
    const hoursOverrides = watch('hoursOverrides', {});
    const sortedMembers = sortBy(members, ['station', 'lastName']);
    const {users = []} = useContext(UserContext);

    const handleUserFilter = useCallback(allowedKey => {
        const keys = ['members', 'absent'];
        const data = {
            members,
            absent
        };

        return docs => {
            return docs.filter(doc => {
                for (const key of keys) {
                    if (key === allowedKey) {
                        continue;
                    }

                    const value = data[key];
                    if (value && value.includes(doc.uid)) {
                        return false;
                    }
                }

                return true;
            });
        };
    }, [members, absent]);

    const calculateAwol = useCallback(() => {
        const otherUsers = (otherDocsAtSameTime || []).reduce((result, doc) => {
            const {members = [], absent = []} = doc;
            return [...result, ...members, ...absent];
        }, []);

        const all = [
            ...otherUsers,
            ...members,
            ...absent
        ].map(user => {
            if (isString(user)) {
                return user;
            }

            const {uid} = user;
            return uid;
        });

        let awol = users.filter(doc => doc.deactivated !== true && FirefighterRanks.includes(doc.role));
        awol = awol.filter(user => !all.includes(user.uid));

        setValue('awol', awol.map(user => user.uid).filter(Boolean), {shouldDirty: true});
    }, [users, members, absent]);

    useDocumentTitle(title);

    useEffect(() => {
        if (date && startTime) {
            // Calculate the new startTime based on the date
            const newStartTime = moment(date).set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second()
            });
    
            // Update startTime only if it has changed
            if (!newStartTime.isSame(startTime)) {
                setValue('startTime', newStartTime, {shouldDirty: true});
            }
    
            if (endTime) {
                // Calculate the new endTime based on the date
                let newEndTime = moment(date).set({
                    hour: endTime.hour(),
                    minute: endTime.minute(),
                    second: endTime.second()
                });
    
                // Adjust endTime if it's before startTime
                if (newEndTime.isBefore(newStartTime)) {
                    newEndTime.add(1, 'day');
                }
    
                // Update endTime only if it has changed
                if (!newEndTime.isSame(endTime)) {
                    setValue('endTime', newEndTime, {shouldDirty: true});
                }
    
                // Calculate hours based on the updated times
                const newDuration = moment.duration(newEndTime.diff(newStartTime));
                const newHours = newDuration.asHours();
    
                // Update hours only if it has changed
                if (newHours !== hours) {
                    setValue('hours', newHours, {shouldDirty: true});
                }
            } else {
                // Reset hours to 0 if no endTime exists, but only if hours isn't already 0
                if (hours !== 0) {
                    setValue('hours', 0, {shouldDirty: true});
                }
            }
        }
    }, [date, startTime, endTime, hours, setValue]);

    const handleHourChange = useCallback((e, member) => {
        let newValue = parseFloat(e.target.value);
        const existing = hoursOverrides || {};

        if (isNaN(newValue)) {
            delete existing[member.uid];

            if (Object.keys(existing).length) {
                setValue('hoursOverrides', existing, {shouldDirty: true});
            } else {
                setValue('hoursOverrides', null, {shouldDirty: true});
            }
        } else {
            if (isNil(existing[member.uid])) {
                if (newValue === 0) {
                    newValue = parseFloat(hours) - 0.5;
                } else {
                    newValue += parseFloat(hours);
                }
            }

            setValue(`hoursOverrides.${member.uid}`, newValue, {shouldDirty: true});
        }
    }, [setValue, hours, hoursOverrides]);

    const onSubmit = useCallback(async data => {
        const dirtyKeys = Object.keys(dirtyFields);
        if (!dirtyKeys.length) {
            return;
        }

        setLoading(true);

        const newData = dirtyKeys.reduce((acc, key) => {
            const value = data[key];
            
            if (Array.isArray(value) && key !== 'memberCompetencies') {
                acc[key] = value.map(v => isString(v) ? v : v.uid);
            } else {
                acc[key] = value;
            }
            
            return acc;
        }, {});

        const toUpdate = ensureJSDates({
            updated: new Date(),
            ...newData
        });

        try {
            if (isNew) {
                const ref = collection(db, 'training');
                await addDoc(ref, toUpdate);
            } else {
                const ref = doc(db, 'training', uid);
                await updateDoc(ref, toUpdate);
            }

            reset(data);

            enqueueSnackbar('Changed saved', {variant: 'success'});

            navigate(-1);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dirtyFields, db, uid, enqueueSnackbar, reset, navigate]);

    const handleGuessJprs = useCallback(async() => {
        setGuessingJprs(true);
        setFoundJprs([]);

        const {data} = await queryStandards({query: description});
        const {results} = data || {};

        const filtered = results.map(found => {
            const {standard: value} = found; // score

            return value;
        });

        enqueueSnackbar(`Found ${results.length} JPR${results.length !== 1 && 's'} based on the title and description`, {variant: 'success'});
        setFoundJprs(results);
        setValue('jprs', uniq([...jprs, ...filtered]), {shouldDirty: true});
        setGuessingJprs(false);
    }, [description, jprs, setValue, enqueueSnackbar, queryStandards]);

    const handleDelete = useCallback(async() => {
        const onDelete = async() => {
            try {
                setDeleting(true);
    
                const ref = doc(db, 'training', uid);
                await deleteDoc(ref);

                navigate('/training');
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        enqueueSnackbar('Are you sure you want to delete this training?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [enqueueSnackbar, db, uid, navigate, closeSnackbar]);

    useEffect(() => {
        if (!date || !startTime || !endTime) {
            return;
        }

        let isSubscribed = true;

        const fetch = async() => {
            console.log('fetching other training records on same date and time');
            const ref = collection(db, 'training');
            const q = query(ref, where('startTime', '>=', startTime.toDate()), where('endTime', '<=', endTime.toDate()));
            
            const raw = await getDocs(q);
            const docs = processRawDocs(raw);

            if (isSubscribed) {
                setOtherDocsAtSameTime(docs.filter(doc => doc.uid !== uid));
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [date, startTime, endTime]);

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'training', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate('/training');
                        return;
                    }

                    let doc = processRawDoc(raw);
                    doc = await populateUsers(db, doc);

                    reset(doc);

                    if (!doc.type) {
                        setValue('type', 'PRACTICE', {shouldDirty: true});
                    }

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, reset, uid, navigate]);

    const handleDeleteJpr = useCallback(jprKey => {
        const newJprs = jprs.filter(j => j !== jprKey);
        setValue('jprs', newJprs, {shouldDirty: true});
    }, [jprs, setValue]);

    const handleAddJpr = useCallback(jprKey => {
        const newJprs = uniq([...jprs, jprKey]);
        setValue('jprs', newJprs, {shouldDirty: true});
    }, [jprs, setValue]);

    const options = Standards.filter(standard => !(jprs || []).includes(standard.value)).map(option => {
        const {standard} = option || {};
        const {title, edition} = standard || {};

        return {
            groupBy: `${title}${edition ? ` (${edition})` : ''}`,
            ...option
        };
    });

    const matchOptions = {insideWords: true,  requireMatchAll: true};

    return (
        <FormProvider {...methods}>
            <Typography variant="h5" gutterBottom>{isNew ? 'Add' : 'Edit'} Training</Typography>

            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
                <Tabs value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
                    <Tab label="Details" value="details" />
                    <Tab label="Attendance" value="attendance" />
                    <Tab label="Skills" value="skills" />
                    <Tab label="Hours" value="customHours" />
                    <Tab label="JPRs" value="jprs" />
                </Tabs>
            </Box>

            <Box sx={{px: 0, display: activeTab === 'details' ? 'block' : 'none'}}>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <TextField
                            fullWidth
                            required
                            label="Title"
                            name="title"
                            disabled={loading}
                        />
                    </Grid>
                    <Grid size={{xs: 12, sm: 4}}>
                        <DatePickerField
                            fullWidth
                            label="Date"
                            name="date"
                            required
                            disabled={loading}
                        />
                    </Grid>
                    <Grid size={{xs: 5, sm: 3}}>
                        <TimeField
                            fullWidth
                            label="Start Time"
                            name="startTime"
                            disabled={loading}
                        />
                    </Grid>
                    <Grid size={{xs: 5, sm: 3}}>
                        <TimeField
                            fullWidth
                            label="End Time"
                            name="endTime"
                            rules={{
                                required: !!startTime
                            }}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid size={2}>
                        <TextField
                            fullWidth
                            label="Hours"
                            name="hours"
                            disabled
                            type="number"
                        />
                    </Grid>
                    <Grid size={12} container spacing={2}>
                        <Grid size={6}>
                            <SelectField
                                fullWidth
                                required
                                name="type"
                                label="Type"
                                options={Object.keys(TrainingTypes).map(value => ({
                                    value,
                                    label: TrainingTypes[value]
                                }))}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={6}>
                            <SelectField
                                fullWidth
                                required
                                name="discipline"
                                label="Discipline"
                                options={Object.keys(TrainingDisciplines).map(value => ({
                                    value,
                                    label: TrainingDisciplines[value]
                                }))}
                                disabled={loading}
                            />
                        </Grid>
                        {/* <Grid size={{sm: 12, lg: 6}}>
                            <CollectionAutocompleteField
                                fullWidth
                                label="Keywords"
                                name="types"
                                collection="trainingTypes"
                                multiple
                                disabled={loading}
                            />
                        </Grid> */}
                    </Grid>

                    <Grid size={12}>
                        <TextField
                            fullWidth
                            multiline
                            label="Description"
                            name="description"
                            disabled={loading}
                        />
                    </Grid>
                    <Grid size={12}>
                        <UserAutocompleteField
                            fullWidth
                            label="Instructors"
                            name="instructors"
                            disabled={loading}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{px: 0, display: activeTab === 'jprs' ? 'block' : 'none'}}>
                <Box sx={{display: 'flex', mb: 2, flexDirection: 'row'}}>
                    <Autocomplete
                        sx={{flex: 1}}
                        options={options}
                        disableCloseOnSelect
                        getOptionLabel={option => {
                            if (!option) {
                                return '';
                            }

                            const {key, title} = option || {};
                            return `${key}: ${title}`;
                        }}
                        isOptionEqualToValue={(option, value) => {
                            return option.value === value.value;
                        }}
                        filterOptions={(options, params) => {
                            const {inputValue} = params;
                            if (!inputValue) {
                                return options;
                            }
                            
                            const keys = ['label', 'key', 'title', 'text'];
                            return options.filter(option => {
                                return keys.some(key => {
                                    const value = option[key] || '';
                                    return match(value, inputValue, matchOptions).length;
                                });
                            });
                        }}
                        groupBy={option => {
                            const {groupBy} = option || {};
                            return groupBy;
                        }}
                        clearOnBlur={false}
                        value={''}
                        inputValue={searchJpr}
                        onChange={(e, value) => value && handleAddJpr(value.value)}
                        onInputChange={(event, newInputValue) => {
                            const {type} = event || {};

                            if (type === 'click') {
                                return;
                            }

                            setSearchJpr(newInputValue);
                        }}
                        renderInput={params => (
                            <UncontrolledTextField
                                {...params}
                                label="Search JPRs"
                                placeholder="4.1.1, hose testing, scba, etc."
                            />
                        )}
                        renderGroup={params => {
                            const {key, group, children} = params;

                            return (
                                <Box key={key}>
                                    <Box sx={{fontWeight: 'bold', backgroundColor: '#f9f9fa', p: 1}}>
                                        {group}
                                    </Box>
                                    {children}
                                </Box>
                            );
                        }}
                        renderOption={(props, option, {inputValue}) => {
                            const {key: optionKey, ...optionProps} = props;

                            const keys = ['key', 'title', 'text'];
                            const {key, title, text} = keys.reduce((result, key) => {
                                const value = option[key] || '';
                                const matches = match(value, inputValue, matchOptions);
                                const parts = parse(value, matches);

                                result[key] = parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ));
                                
                                return result;
                            }, {});

                            return (
                                <Box component="li" key={optionKey} {...optionProps} sx={{borderTop: 1, borderColor: '#eee'}}>
                                    <Box>
                                        <Typography sx={{fontWeight: 'bold'}}>{key}. {title}</Typography> {text}
                                    </Box>
                                </Box>
                            );
                        }}
                    />
                    
                    <Tooltip title="Guess JPRs from title and description using AI">
                        <LoadingButton onClick={handleGuessJprs} startIcon={<AutoFixHighIcon />} disabled={!title && !description} loading={guessingJprs} size="large" variant="contained" sx={{ml: 1}}>Guess JPRs</LoadingButton>
                    </Tooltip>
                </Box>

                {jprs && jprs.map((jprKey, index) => {
                    const {standard, key, title, text: rawText, data = {}, parent} = getStandardForKey(jprKey) || {};
                    const {title: standardTitle, edition} = standard || {};
                    const {title: parentTitle} = parent || {};
                    const subsections = Object.keys(data).filter(key => parseInt(key) > 0);

                    const lines = (rawText || '').split('\n');
                    let [text, ...otherLines] = lines;
                    if (text && otherLines.length > 0 && text.startsWith('(')) {
                        otherLines = [text, ...otherLines].filter(Boolean);
                        text = '';
                    }

                    const found = foundJprs.find(found => found.standard === jprKey);
                    const {score} = found || {};
                    const canExpand = subsections.length > 0;
                    const isExpanded = get(expanded, jprKey, false);

                    return (
                        <Card variant="outlined" {...found && {severity: 'warning'}} key={`jpr-${index}`} sx={{mb: 1}}>
                            <CardContent sx={{position: 'relative', '&:last-child': {pb: 1}}}>
                                <Stack direction="row" spacing={1} sx={{alignItems: 'flex-start'}}>
                                    <Box sx={{flex: 1}}>
                                        {standardTitle && <Typography gutterBottom sx={{color: 'text.secondary'}}>{standardTitle}{edition && ` (${edition})`}</Typography>}
                                        {parentTitle && <Box sx={{fontWeight: 'bold', mb: 1}}>{parentTitle}</Box>}
                                    </Box>
                                    <Stack direction="row" spacing={1}>
                                        {found && (
                                            <Tooltip title="AI Match Score">
                                                <Box
                                                    sx={{
                                                        gap: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        minHeight: '2.5rem',
                                                        padding: '8px 12px',
                                                        borderRadius: 'var(--template-shape-borderRadius)',
                                                        backgroundColor: alpha(orange[100], 0.5),
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    <AutoAwesomeIcon />
                                                    {(score * 100).toFixed(0)}%
                                                </Box>
                                            </Tooltip>
                                        )}
                                        <IconButton onClick={() => handleDeleteJpr(jprKey)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                </Stack>

                                <span style={{fontWeight: 'bold'}}>{key}. {title && `${title}${text ? '.' : ''} `}</span>
                                {text && <span>{text}</span>}

                                {otherLines.length > 0 && (
                                    <Box sx={{ml: 1, mt: 0.5}}>
                                        {otherLines.map((line, i) => <Box key={`${jprKey}.line.${i}`}>{line}</Box>)}
                                    </Box>
                                )}

                                {canExpand && (
                                    <Box sx={{mt: 1}}>
                                        <SmallChip icon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} label={isExpanded ? 'Show Less' : 'Show More'} onClick={() => setExpanded({...expanded, [jprKey]: !isExpanded})} />
                                    </Box>
                                )}

                                {isExpanded && subsections.map((subsection, index) => <StandardSubsection key={`${jprKey}-${index}`} parent={data} chapter={key} subsection={subsection} />)}
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>

            <Box sx={{px: 0, display: activeTab === 'attendance' ? 'block' : 'none'}}>
                <Stack direction="column" spacing={2} sx={{alignItems: 'flex-end'}}>
                    {usesIAR && (
                        <ImportDialog date={date} startTime={startTime} endTime={endTime} usersKey="members" />
                    )}

                    <UserAutocompleteField
                        fullWidth
                        label="Members"
                        name="members"
                        disabled={loading}
                        onFilter={handleUserFilter('members')}
                    />

                    <UserAutocompleteField
                        fullWidth
                        label="Absent"
                        name="absent"
                        disabled={loading}
                        onFilter={handleUserFilter('absent')}
                    />

                    <UserAutocompleteField
                        fullWidth
                        label="AWOL"
                        name="awol"
                        disabled={loading}
                    />

                    <Button variant="contained" size="small" onClick={calculateAwol}>Calculate AWOL</Button>
                </Stack>
            </Box>

            <Box sx={{px: 0, display: activeTab === 'skills' ? 'block' : 'none'}}>
                <Skills />
            </Box>

            <Box sx={{px: 0, display: activeTab === 'customHours' ? 'block' : 'none'}}>
                <MemberHours
                    members={sortedMembers}
                    defaultSalary={hours}
                    salaryOverrides={hoursOverrides}
                    handleHourChange={handleHourChange}
                />
            </Box>

            <Divider sx={{mt: 2}} />

            <Box sx={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', my: 2}}>
                {!isNew && (
                    <LoadingButton onClick={handleDelete} sx={{mr: 1}} loading={deleting} component="label">
                        Delete Training
                    </LoadingButton>
                )}
                
                <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    disabled={loading || dirtyKeys.length === 0}
                    loading={loading}
                >
                    Save Training
                </LoadingButton>
            </Box>
        </FormProvider>
    );
}