import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Typography, Divider, Stack, Box, Grid2 as Grid, Tabs, Tab} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm, useFormContext, FormProvider} from 'react-hook-form';
import {doc, collection, addDoc, deleteDoc, updateDoc} from 'firebase/firestore';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import {useConfirm} from 'material-ui-confirm';
import {IncidentTypes} from '@embertracking/common';
import moment from 'moment';

import {db} from '-/firebase';
import {ensureJSDates, getCollectionDoc} from '-/data/utils';
import {SettingsContext} from '-/contexts/Settings';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import TextField from '-/form/TextField.js';
import DatePickerField from '-/form/DatePickerField.js';
import SelectField from '-/form/SelectField.js';
import TimeField from '-/form/TimeField.js';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import ApparatusField from '-/form/ApparatusField';
import GoogleAutocompleteField from '-/form/GoogleAutocompleteField';

import ImportDialog from '-/pages/responses/ImportDialog';

import Map from './incidents/Map';

const LocationMap = props => {
    const {loading} = props;
    const {watch} = useFormContext();
    const type = watch('type');
    const location = watch('location');

    if (!location) {
        return null;
    }

    return (
        <Stack sx={{mb: 2}}>
            <Map sx={{flex: 1}} incident={{type, location}} editable={!loading} mapContainerStyle={{height: 300}} />

            <Typography sx={{mt: 1, color: 'text.secondary'}} variant="caption">Drag the marker on the map to change exact location</Typography>
        </Stack>
    );
};

export default function Incident() {
    const {id: uid} = useParams();
    const isNew = !uid;
    const [loading, setLoading] = useState(!!uid);
    const [deleting, setDeleting] = useState(false);
    const [activeTab, setActiveTab] = useState('details');
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const confirm = useConfirm();
    const {usesIAR} = useContext(SettingsContext);

    const methods = useForm({
        defaultValues: {
            type: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, reset, watch, setValue} = methods;
    const {isDirty, dirtyFields} = formState;

    const incidentNumber = watch('incidentNumber');
    const location = watch('location');
    const date = watch('date');
    const dispatchTime = watch('dispatchTime');
    const arrivalTime = watch('arrivalTime');
    const sceneCleared = watch('sceneCleared');
    const users = watch('users') || [];
    const standby = watch('standby') || [];
    const cancelled = watch('cancelled') || [];

    useEffect(() => {
        if (date && typeof dispatchTime === 'string') {
            try {
                const [hour, minute] = dispatchTime.split(':');

                const newDispatchTime = moment(date).set({
                    hour,
                    minute,
                    second: 0
                });

                setValue('dispatchTime', newDispatchTime, {shouldDirty: true});
            } catch(e) {
                //
            }
        }

        if (date && typeof arrivalTime === 'string') {
            try {
                const [hour, minute] = arrivalTime.split(':');

                const newArrivalTime = moment(date).set({
                    hour,
                    minute,
                    second: 0
                });

                setValue('arrivalTime', newArrivalTime, {shouldDirty: true});
            } catch(e) {
                //
            }
        }

        if (date && typeof sceneCleared === 'string') {
            try {
                const [hour, minute] = sceneCleared.split(':');

                const newSceneCleared = moment(date).set({
                    hour,
                    minute,
                    second: 0
                });

                setValue('sceneCleared', newSceneCleared, {shouldDirty: true});
            } catch(e) {
                //
            }
        }
    }, [dispatchTime, arrivalTime, sceneCleared, date]);

    useEffect(() => {
        if (date && typeof dispatchTime === 'object') {
            const newDateWithTime = moment(date).set({
                hour: dispatchTime.hours(),
                minute: dispatchTime.minutes(),
                second: 0
            });

            const isSame = newDateWithTime.isSame(date);

            if (!isSame) {
                setValue('date', newDateWithTime, {shouldDirty: true});
            }
        }
    }, [date, dispatchTime]);

    useDocumentTitle(incidentNumber ? incidentNumber : 'Incident');

    const onSubmit = useCallback(async data => {
        const dirtyKeys = Object.keys(dirtyFields);
        if (!dirtyKeys.length) {
            return;
        }

        setLoading(true);

        const newData = dirtyKeys.reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {});

        try {
            const toUpdate = ensureJSDates({
                updated: new Date(),
                ...newData
            });

            if (isNew) {
                const ref = collection(db, 'incidents');
                await addDoc(ref, toUpdate);
            } else {
                const ref = doc(db, 'incidents', uid);
                await updateDoc(ref, toUpdate);
            }

            reset(data);

            enqueueSnackbar(isNew ? 'Incident saved' : 'Changed saved', {variant: 'success'});

            navigate(-1);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dirtyFields, db, uid, enqueueSnackbar, reset, navigate, isNew]);

    const handleDelete = useCallback(async() => {
        const onDelete = async() => {
            try {
                setDeleting(true);
    
                const ref = doc(db, 'incidents', uid);
                await deleteDoc(ref);

                navigate(-1);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        try {
            await confirm({
                description: 'Are you sure you want to delete this incident?',
                confirmationText: 'Delete Incident'
            });

            onDelete();
        } catch(e) {
            //
        }
    }, [confirm, enqueueSnackbar]);

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const incident = await getCollectionDoc(db, 'incidents', uid);
                if (!incident) {
                    navigate(-1);
                    return;
                }
                
                if (isSubscribed) {
                    reset(incident);

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, reset, uid, navigate]);

    const typeOptions = Object.keys(IncidentTypes).map(key => {
        const label = IncidentTypes[key];
        return {value: key, label};
    });

    const handleUserFilter = useCallback(allowedKey => {
        const keys = ['users', 'standby', 'cancelled'];
        const data = {
            users,
            standby,
            cancelled
        };

        return docs => {
            return docs.filter(doc => {
                for (const key of keys) {
                    if (key === allowedKey) {
                        continue;
                    }

                    const value = data[key] || [];
                    if (value && value.includes(doc.uid)) {
                        return false;
                    }
                }

                return true;
            });
        };
    }, [users, standby, cancelled]);

    return (
        <FormProvider {...methods}>
            <Typography variant="h5" gutterBottom>{isNew ? 'Add' : 'Edit'} Incident</Typography>

            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
                <Tabs value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
                    <Tab label="Details" value="details" />
                    <Tab label="Responses" value="responses" />
                </Tabs>
            </Box>

            {activeTab === 'details' && (
                <Grid container spacing={2}>
                    {location && (
                        <Grid size={{xs: 12, md: 4}}>
                            <LocationMap {...{loading}} />
                        </Grid>
                    )}

                    <Grid size={{md: location ? 8 : 12}}>
                        <Grid container rowSpacing={2} columnSpacing={1}>
                            <Grid size={6}>
                                <TextField
                                    fullWidth
                                    label="Incident #"
                                    name="incidentNumber"
                                    required
                                    disabled={loading}
                                />
                            </Grid>

                            <Grid size={6}>
                                <DatePickerField
                                    fullWidth
                                    label="Date"
                                    name="date"
                                    required
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid size={4}>
                                <TimeField
                                    fullWidth
                                    label="Dispatch Time"
                                    name="dispatchTime"
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid size={4}>
                                <TimeField
                                    fullWidth
                                    label="Arrival Time"
                                    name="arrivalTime"
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid size={4}>
                                <TimeField
                                    fullWidth
                                    label="Scene Cleared"
                                    name="sceneCleared"
                                    disabled={loading}
                                />
                            </Grid>

                            <Grid size={12}>
                                <GoogleAutocompleteField
                                    fullWidth
                                    label="Location"
                                    name="location"
                                    disabled={loading}
                                />
                            </Grid>

                            <Grid size={8}>
                                <SelectField
                                    label="Type"
                                    name="type"
                                    fullWidth
                                    required
                                    multiple={false}
                                    disabled={loading}
                                    options={typeOptions}
                                />
                            </Grid>
                            <Grid size={4}>
                                <TextField
                                    fullWidth
                                    label="Incident Type Code"
                                    name="incidentTypeCode"
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid size={12}>
                                <TextField
                                    fullWidth
                                    label="Notes"
                                    name="notes"
                                    multiline
                                    rows={4}
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {activeTab === 'responses' && (
                <Stack spacing={2}>
                    <ApparatusField
                        fullWidth
                        label="Apparatus"
                        name="apparatus"
                        disabled={loading}
                    />

                    <UserAutocompleteField
                        fullWidth
                        label="Members"
                        name="users"
                        onFilter={handleUserFilter('users')}
                        disabled={loading}
                    />
                    
                    <UserAutocompleteField
                        fullWidth
                        label="Standby"
                        name="standby"
                        onFilter={handleUserFilter('standby')}
                        disabled={loading}
                    />

                    <UserAutocompleteField
                        fullWidth
                        label="Cancelled"
                        name="cancelled"
                        onFilter={handleUserFilter('cancelled')}
                        disabled={loading}
                    />

                    {usesIAR && (
                        <ImportDialog date={date} startTime={dispatchTime} endTime={sceneCleared} />
                    )}
                </Stack>
            )}

            <Divider sx={{mt: 2}} />

            <Box sx={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', mt: 2, mb: 2}}>
                {!isNew && (
                    <LoadingButton onClick={handleDelete} sx={{mr: 1}} loading={deleting}>
                        Delete Incident
                    </LoadingButton>
                )}
                
                <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isDirty || loading}
                    loading={loading}
                >
                    Save Incident
                </LoadingButton>
            </Box>
        </FormProvider>
    );
}